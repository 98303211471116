import type { GetterTree, Module, MutationTree } from 'vuex';
import type { LoaderState } from '#root/src/types/store/loader';
import type MainState from '#root/src/types/store';

const defaultState: LoaderState = {
  isPageLoading: false,
};

const mutations: MutationTree<LoaderState> = {
  setIsPageLoading(state, payload) {
    state.isPageLoading = payload;
  },
};

const getters: GetterTree<LoaderState, MainState> = {
  isPageLoading: ({ isPageLoading }) => isPageLoading,
};

export const loaderModule: Module<LoaderState, MainState> = {
  namespaced: true,
  getters,
  mutations,
  state: defaultState,
};
