import type { CabinetState, TrackingState } from '@/types/store/cabinet';

export const store = {
  login_phone: null,
};
export const getEmptyTracking = (): TrackingState => ({
  trackId: '',
  trackInfo: null,
  loadingTrackingHistory: false,
  trackingHistory: null,
});

export const initialCabinet = (): CabinetState => ({
  login_phone: null,
  email: null,
  tracking: getEmptyTracking(),
  isStateLogout: false,
  isStateLoginProcessing: true,
  user: {
    contragent_uuid: null,

    contragent: {},
    cdekid_status: null,
    profile: {
      firstName: null,
      lastName: null,
      fatherName: null,
      phone: null,
      email: null,
      inn: null,
      gender: null,
      passportSeries: null,
      passportNumber: null,
      dateBirth: null,
      passportIssuedBy: null,
      passportIssueDate: null,
      surName: null,
      addressFact: {},
      addressReal: {},
      is_email_confirmed: false,
    },
  },
  ordersHasUpdates: false,
  redirectedFromSiteCdekid: false,
  startCreateOrderAtSite: false,
  startCreateOrderAtBox: false,
  isRedirectedFromSiteOrderCreate: false,
  hiddenAlerts: [],
  isChangeOrder: false,
  lastDateUserLoaded: null,
  isHideOrderCreditBtn: false,
  webviewToken: null,
});
const state = () => ({
  ...initialCabinet(),
});

export default state;
