import type { I18n } from 'vue-i18n';
import { createI18n } from 'vue-i18n';
import { nextTick } from 'vue';
import { getExpressApi } from '#root/src/api-clients/express-api';
import { getEnvUniversal, castEnv } from '#root/src/utils/env-utils';

const expressApi = getExpressApi();

function ruPluralRules(choice: number, choicesLength: number) {
  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (choicesLength < 4) {
    return !teen && endsWithOne ? 1 : 2;
  }

  if (!teen && endsWithOne) {
    return 1;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
}

const VITE_LOCALES_FROM_JSON = castEnv(getEnvUniversal('VITE_LOCALES_FROM_JSON'));

// Русский всегда дефолтный язык, если добавляются ключи
// не забудем добавить и в остальные языки
export async function loadLocaleMessages(i18n: I18n, locale: string, isStorybook = false) {
  if (isStorybook) {
    const data = await import('../../server/messages/ru.json');
    i18n.global.setLocaleMessage(locale, data);
    return nextTick();
  }

  if (VITE_LOCALES_FROM_JSON) {
    const messages = await expressApi.get(`/messages/${locale}`);
    i18n.global.setLocaleMessage(locale, messages.data);
  } else {
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `./messages/${locale}.js`
    );
    i18n.global.setLocaleMessage(locale, messages.default);
  }

  return nextTick();
}

export default async function i18nConfig(locale: string, isStorybook = false) {
  const DEFAULT_LOCALE = 'ru';

  const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale,
    fallbackLocale: DEFAULT_LOCALE,
    warnHtmlMessage: false, // disable warning HTML in message
    pluralRules: {
      ru: ruPluralRules,
    },
  });

  const localesPromises: Promise<void>[] = [];

  if (locale !== DEFAULT_LOCALE) {
    // Дефолтная локаль должна быть всегда
    localesPromises.push(loadLocaleMessages(i18n, DEFAULT_LOCALE, isStorybook));
  }

  localesPromises.push(loadLocaleMessages(i18n, locale, isStorybook));

  await Promise.all(localesPromises);

  return i18n;
}
