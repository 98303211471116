import type { ActionTree } from 'vuex';
import type { CabinetState } from '@/types/store/cabinet';
import type MainState from '@/types/store';
import { initialCabinet } from '@/store/vuex-store/modules/cabinetModule/cabinetModuleState';
import { setItemLS } from '@/utils/local-storage.utils';
import { CATEGORY } from '@/composables/use-test-abc';

const cabinetModuleActions: ActionTree<CabinetState, MainState> = {
  removeMetrics({ commit }) {
    commit('setStartCreateOrderAtBox', false);
    commit('setStartCreateOrderAtSite', false);
  },
  logoutFromCabinet(store) {
    store.commit('setIsStateLogout');

    store.commit('keycloak/resetKeycloak', {}, { root: true });
    store.commit('resetState');
    store.commit('setLoginPhone', null);
    store.commit('resetTracking');
    store.commit('setOrdersHasUpdates', false);
    store.commit('setUser', { ...initialCabinet().user });
    // Удалил ручную чистку, так как весь стор хранится в ls и можно очистить его
    // После логаута страницу всегда перезагружаем
    setTimeout(() => {
      const valueToKeep = localStorage.getItem(CATEGORY.SSO);

      // Если не в следующем макростаске, то стор перезапишется
      localStorage.clear();

      if (valueToKeep !== null) {
        localStorage.setItem(CATEGORY.SSO, valueToKeep);
      }
    });
  },
  setTracking({ commit }, payload) {
    commit('recordTrackingData', payload);
  },
  setStartCreateOrderAtSite({ commit }, payload) {
    commit('setStartCreateOrderAtSite', payload);
  },
  updateOrderAfterCheckPhone({ commit }, payload) {
    commit('updateAvailableTime', {
      orderNumber: payload.orderNumber,
      availableTimeForDelivery: payload.availableTimeForDelivery,
    });
    commit('updateReceiverAddress', {
      orderNumber: payload.orderNumber,
      receiverAddress: payload.receiverAddress,
    });
  },
  setIsRedirectedFromSite({ commit }, payload) {
    commit('setIsRedirectedFromSite', payload);
  },
  setIsChangeOrder({ commit }, payload) {
    commit('setIsChangeOrder', payload);
  },
  tabVisibilityChange({ dispatch, rootGetters }) {
    if (
      rootGetters['auth/getImpersonationToken'] &&
      rootGetters['auth/isImpersonationTokenExpired']()
    ) {
      dispatch('auth/removeImpersonation', true, { root: true });
    }
  },
  setIsHideOrderCreditBtn({ commit }, payload) {
    if (payload) {
      setItemLS('is-hide-order-credit-btn', 'true');
    } else {
      setItemLS('is-hide-order-credit-btn', 'false');
    }

    commit('setIsHideOrderCreditBtn', payload);
  },
};
export default cabinetModuleActions;
