import type { LkWebsiteId } from '@/env';
import type { Lang } from '@/types/locales';

// Я не знаю почему тут нет беларуси, но оно же сейчас работает
// А если добавим может чета отвалиться
const localesMap: Record<Exclude<LkWebsiteId, 'by'>, string> = {
  kz: 'kaz',
  ru: 'rus',
  am: 'hye',
  kg: 'kir',
  ge: 'kat',
  uz: 'uzb',
  az: 'aze',
};

export const getMappedLocale = (lang: Lang) => {
  return localesMap[lang];
};
