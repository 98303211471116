import type { CdekOfficeFilter, OfficeCoords, OfficeRequestData } from '@/types/dto/cdek-office';

export function getOfficeUrl(
  baseUrl: string,
  data: Partial<OfficeRequestData>,
  filter?: Partial<CdekOfficeFilter>,
) {
  const { locale } = data;

  let url = `${baseUrl}?locale=${locale}`;

  if (data && data.cityCode) {
    url += `&cityCode=${data.cityCode}`;
  }

  if (data && data.coords) {
    const keys = Object.keys(data.coords) as (keyof OfficeCoords)[];

    keys.forEach((key) => {
      url += `&${encodeURI(`coords[${key}]=${data.coords![key]}`)}`;
    });
  }

  if (filter?.hasDressingRoom) {
    url += `&hasDressingRoom=${filter.hasDressingRoom}`;
  }

  if (filter?.hasFulfillment) {
    url += `&hasFulfillment=${filter.hasFulfillment}`;
  }

  if (filter?.pvzType) {
    url += `&type=${filter.pvzType}`;
  }

  if (filter?.maxWeight) {
    url += `&maxWeight=${filter.maxWeight}`;
  }

  if (filter?.maxHeight) {
    url += `&maxHeight=${filter.maxHeight}`;
  }

  if (filter?.maxDepth) {
    url += `&maxDepth=${filter.maxDepth}`;
  }

  if (filter?.maxWidth) {
    url += `&maxWidth=${filter.maxWidth}`;
  }

  if (filter?.isWorksOnWeekend) {
    url += `&isWorksOnWeekend=true`;
  }

  if (filter?.isMoreThanMaxWeight) {
    url += '&isMoreThanMaxWeight=true';
  }

  if (filter?.isLtl) {
    url += `&isLtl=true`;
  }

  return url;
}
