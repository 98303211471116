export const getCabinetUrl = (pathname: string, searchOriginal = '', currentLocale = 'ru') => {
  let newUrl;

  if (pathname.includes('/tracking')) {
    newUrl = `/${currentLocale}/cabinet/orders/`;
  }

  if (pathname.includes('/calculate')) {
    newUrl = `/${currentLocale}/cabinet/calculate/`;
  }

  if (newUrl) {
    if (searchOriginal) {
      newUrl += searchOriginal;
    }

    return newUrl;
  }

  if (pathname.includes('/cdekid')) {
    return `/${currentLocale}/cabinet/cdekid/`;
  }

  if (pathname.includes('/gifts')) {
    return `/${currentLocale}/cabinet/gifts/`;
  }

  return '';
};
