// Сначала ищем websiteID, потом локаль
import type { WebsiteId } from '@/env';
import type { CdekDefaultCity } from '@/types/dto/city';

// ЭТО ДЕФОЛТНЫЙ ГОРОД ДЛЯ ОТОБРАЖЕНИЯ НА СТРАНИЦЫ ОФИСОВ
export const DEFAULT_CITIES: Record<WebsiteId, Record<string, CdekDefaultCity>> = {
  ru: {
    ru: {
      country: 'Россия',
      country_code: 'ru',
      locale: 'ru',
      id: 14895,
      cdek_id: 44,
      name: 'Москва',
      fullname: 'Москва, Москва, Россия',
      url: 'moscow',
    },
  },
  kz: {
    ru: {
      country: 'Казахстан',
      country_code: 'kz',
      locale: 'ru',
      id: 14974,
      cdek_id: 4961,
      name: 'Нур-Султан (Астана)',
      fullname: 'Нур-Султан (Астана), Акмолинская обл., Казахстан',
      url: 'nur-sultan-astana',
    },
    kz: {
      country: 'Казахстан',
      country_code: 'kz',
      locale: 'kz',
      id: 9372500,
      cdek_id: 4961,
      name: 'Nur-Sultan (Astana)',
      fullname: 'Nur-Sultan (Astana), Akmolinskaya obl., Kazakhstan',
      url: 'nur-sultan-astana',
    },
  },
  cn: {
    zh: {
      country: 'Китай (КНР)',
      country_code: 'cn',
      locale: 'zh',
      id: 15919,
      cdek_id: 10150,
      name: '北京市',
      fullname: '北京市, 北京, 中国',
      url: 'beijing',
    },
    ru: {
      country: 'Китай (КНР)',
      country_code: 'cn',
      locale: 'ru',
      id: 15373,
      cdek_id: 10150,
      name: 'Пекин',
      fullname: 'Пекин, Пекин, Китай (КНР)',
      url: 'beijing',
    },
  },
  by: {
    ru: {
      country: 'Белоруссия (Беларусь)',
      country_code: 'by',
      locale: 'ru',
      id: 14940,
      cdek_id: 9220,
      name: 'Минск',
      fullname: 'Минск, Минская обл., Белоруссия (Беларусь)',
      url: 'minsk',
    },
  },
  ge: {
    ru: {
      country: 'Грузия',
      country_code: 'ge',
      locale: 'ru',
      id: 16316987,
      cdek_id: 11564,
      name: 'Тбилиси',
      fullname: 'Грузия, Тбилиси',
      url: 'tbilisi',
    },
    ge: {
      country: 'Georgia',
      country_code: 'ge',
      locale: 'ge',
      id: 20407617,
      cdek_id: 11564,
      name: 'Tbilisi',
      fullname: 'Georgia, Tbilisi',
      url: 'tbilisi',
    },
  },
  uz: {
    ru: {
      country: 'Узбекистан',
      country_code: 'uz',
      locale: 'ru',
      id: 15305,
      cdek_id: 11562,
      name: 'Ташкент',
      fullname: 'Узбекистан, Ташкент',
      url: 'tashkent',
    },
    uz: {
      country: 'Uzbekistan',
      country_code: 'uz',
      locale: 'uz',
      id: 20414041,
      cdek_id: 11562,
      name: 'Tashkent',
      fullname: 'Uzbekistan, Tashkent',
      url: 'tashkent',
    },
  },
  kg: {
    ru: {
      country: 'Кыргызстан',
      country_code: 'kg',
      locale: 'ru',
      id: 14926,
      cdek_id: 5444,
      name: 'Бишкек',
      fullname: 'Киргызстан, город Бишкек, Бишкек',
      url: 'bishkek',
    },
    kg: {
      country: 'Kyrgyzstan',
      country_code: 'kg',
      locale: 'kg',
      id: 16390970,
      cdek_id: 5444,
      name: 'Bishkek',
      fullname: 'Bishkek, Chui region, Kyrgyzstan',
      url: 'bishkek',
    },
  },
  am: {
    ru: {
      country: 'Армения',
      country_code: 'am',
      locale: 'ru',
      id: 15308,
      cdek_id: 7114,
      name: 'Ереван',
      fullname: 'Ереван',
      url: 'erevan',
    },
    am: {
      country: 'Armenia',
      country_code: 'am',
      locale: 'am',
      id: 16396240,
      cdek_id: 7114,
      name: 'Erevan',
      fullname: 'Erevan',
      url: 'erevan',
    },
  },
  az: {
    ru: {
      id: 8066219,
      cdek_id: 5090,
      name: 'Баку',
      fullname: 'Азербайджан, Баку, Баку',
      locale: 'ru',
      country_code: 'az',
      country: 'Азербайджан',
      url: 'baku',
    },
    az: {
      id: 8066219,
      cdek_id: 5090,
      name: 'Baku',
      fullname: 'Baku',
      locale: 'az',
      country_code: 'az',
      country: 'Azerbaijan',
      url: 'baku',
    },
  },
};
