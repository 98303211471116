import type { Module } from 'vuex';
import type { CabinetState } from '@/types/store/cabinet';
import type MainState from '@/types/store';
import cabinetModuleActions from './cabinetModuleActions';
import cabinetModuleState from './cabinetModuleState';
import cabinetModuleMutations from './cabinetModuleMutations';
import cabinetModuleGetters from './cabinetModuleGetters';

export const cabinetModule: Module<CabinetState, MainState> = {
  namespaced: true,
  actions: cabinetModuleActions,
  state: cabinetModuleState,
  getters: cabinetModuleGetters,
  mutations: cabinetModuleMutations,
};
