import type { FooterData, FooterState } from '@/types/store/footer';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import type MainState from '@/types/store';

export enum FooterType {
  SET_FOOTER = 'SET_FOOTER',
  LOADING_FOOTER = 'LOADING_FOOTER',
  START_LOADING_FOOTER = 'START_LOADING_FOOTER',
  STOP_LOADING_FOOTER = 'STOP_LOADING_FOOTER',
}

const defaultFooter: FooterData = {
  color: null,
  feedback: {
    phone: '',
  },
  links: {
    additional: [],
    international: [],
    official: [],
    social: [],
    application: [],
    locale: null,
    websiteId: null,
    feedback: null,
  },
  locale: null,
  search: null,
  websiteId: null,
};

export const getDefaultFooterState = (): FooterState => ({
  footer: { ...defaultFooter },
  loading: false,
});

const mutations: MutationTree<FooterState> = {
  [FooterType.SET_FOOTER](state, { footer }) {
    state.footer = { ...footer };
  },
  [FooterType.LOADING_FOOTER](state, loading) {
    state.loading = loading;
  },
};

const actions: ActionTree<FooterState, MainState> = {
  [FooterType.SET_FOOTER]({ commit }, payload) {
    commit(FooterType.SET_FOOTER, payload);
  },
  [FooterType.START_LOADING_FOOTER]({ commit }) {
    commit(FooterType.LOADING_FOOTER, true);
  },
  [FooterType.STOP_LOADING_FOOTER]({ commit }) {
    commit(FooterType.LOADING_FOOTER, false);
  },
};

const getters: GetterTree<FooterState, MainState> = {
  footer({ footer }) {
    return footer;
  },
  loading({ loading }) {
    return loading;
  },
};

export const footerModule: Module<FooterState, MainState> = {
  namespaced: true,
  actions,
  getters,
  state: getDefaultFooterState,
  mutations,
};
