import type { KeycloakData } from '@/types/store/keycloak';
import type KeycloakState from '@/types/store/keycloak';
import type { GetterTree, Module, MutationTree } from 'vuex';
import type MainState from '@/types/store';

const initialKeycloak = (): KeycloakData => ({
  access_token: null,
  expires_in: null,
  expired_at: null,
  id_token: null,
  'not-before-policy': null,
  refresh_token: null,
  refresh_expires_in: null,
  refresh_expired_at: null,
  scope: null,
  session_state: null,
  token_type: null,
});

const state = (): KeycloakState => ({
  keycloak: initialKeycloak(),
});
const mutations: MutationTree<KeycloakState> = {
  /**
   * @param {Object} state
   * @param {KeycloakResponse<Object>} payload
   */
  setKeycloak(state, payload) {
    const date = new Date();
    const accessTokenExpiredSecs = parseInt(payload.expires_in, 10);
    const accessTokenExpiredMsecs = accessTokenExpiredSecs * 1000;
    const accessTokenExpiredDate = new Date(
      accessTokenExpiredMsecs ? date.getTime() + accessTokenExpiredMsecs : 2 ** 31 * 1000,
    );
    const refreshTokenExpiredSecs = parseInt(payload.refresh_expires_in, 10);
    const refreshTokenExpiredMsecs = refreshTokenExpiredSecs * 1000;
    const refreshTokenExpiredDate = new Date(
      refreshTokenExpiredMsecs ? date.getTime() + refreshTokenExpiredMsecs : 2 ** 31 * 1000,
    );

    state.keycloak = {
      ...payload,

      expired_at: accessTokenExpiredDate,
      refresh_expired_at: refreshTokenExpiredDate,
    };
  },
  resetKeycloak(state) {
    state.keycloak = initialKeycloak();
  },
};

const getters: GetterTree<KeycloakState, MainState> = {
  keycloak(state) {
    return state.keycloak;
  },

  accessToken(state) {
    return state.keycloak.access_token;
  },

  refreshToken(state) {
    return state.keycloak.refresh_token;
  },

  expiredAt(state) {
    return state.keycloak.expired_at;
  },

  refreshExpiredAt(state) {
    return state.keycloak.refresh_expired_at;
  },
};

export const keycloakModule: Module<KeycloakState, MainState> = {
  namespaced: true,
  getters,
  state,
  mutations,
};
