import type { App, UnwrapNestedRefs } from 'vue';
import { navigate } from 'vite-plugin-ssr/client/router';
import { reactive } from 'vue';
import type { PageContext } from '@/renderer/types';
import { setLocaleToLink } from '@/utils/set-locale-to-link';
import { getCabinetUrl } from '@/utils/getCabinetUrl';

export default {
  install: (app: App, pageContext: UnwrapNestedRefs<PageContext>, runsOnClient: boolean) => {
    const route = reactive({
      path: pageContext.urlPathname,
    });

    app.provide('route', route);
    app.provide('changeRoute', (path: string) => {
      route.path = path;
    });

    app.config.globalProperties.$route = route;

    const push = async (url: string | { path: string; query?: Record<string, string> }) => {
      let resultPath = '';

      if (typeof url === 'string') {
        resultPath = url;
      } else {
        resultPath = url.path;

        if (url.query) {
          const queryString = Object.keys(url.query)
            .map((el) => `${el}=${url.query && url.query[el]}`)
            .join('&');

          if (queryString) {
            resultPath += `?${queryString}`;
          }
        }
      }

      const store = app.config.globalProperties?.$store;

      const isLoggedInCabinet = store?.getters?.isLoggedInCabinet;

      if (isLoggedInCabinet) {
        const parsedUrl = new URL(resultPath, window.location.origin);

        const { search, pathname } = parsedUrl;

        const cabinetUrl = getCabinetUrl(pathname, search, pageContext.locale);

        if (cabinetUrl) {
          window.location.href = cabinetUrl;
          return;
        }
      }

      // Я подсовываю тут локаль, потому что у нас не везде ссылки указаны правильно
      // Если нет локали, то мы ожидаем что попадем на текущую локаль
      resultPath = setLocaleToLink(resultPath, pageContext.locale, true);

      await navigate(resultPath);
      app.config.globalProperties.$route.path = pageContext.urlPathname;
    };

    app.config.globalProperties.$router = {
      push,
    };

    if (runsOnClient) {
      window.clientNavigate = push;
    }
  },
};
