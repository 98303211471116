import { LOYALTY_TYPE } from '@/middleware/mutation-types';
import type MainState from '@/types/store';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import type { LoyaltyState } from '@/types/store/loyalty';

export const initialLoyalty = (): LoyaltyState => ({
  availableBonuses: 0,
  burningBonuses: 0,
  texts: {
    burningRules: '',
    loyaltyDetailRules: '',
    loyaltyShortRules: '',
  },
  showcaseParams: null,
  smsNotificationsEnabled: false,
  isLoading: false,
  canUseRefCode: true,
  referralCard: {
    code: '',
    title: '',
    subtitle: '',
    invite: '',
    description: '',
    referrersNumber: '',
    buttonText: '',
    buttonClickedText: '',
  },
  giftStoreLinkText: '',
});

const state = (): LoyaltyState => ({
  ...initialLoyalty(),
});

const actions: ActionTree<LoyaltyState, MainState> = {
  addBonuses({ commit, state }, bonuses) {
    const newAmount = state.availableBonuses + bonuses;
    commit(LOYALTY_TYPE.SET_AVAILABLE_BONUSES, newAmount);
  },
};
const mutations: MutationTree<LoyaltyState> = {
  [LOYALTY_TYPE.SET_BURNING_BONUSES](store, payload) {
    store.burningBonuses = payload;
  },
  [LOYALTY_TYPE.SET_AVAILABLE_BONUSES](store, payload) {
    store.availableBonuses = payload;
  },
  [LOYALTY_TYPE.SET_TEXTS](store, payload) {
    store.texts = { ...payload };
  },
  [LOYALTY_TYPE.SET_SMS_SUBSCRIPTION](store, payload) {
    store.smsNotificationsEnabled = payload;
  },
  init(
    state,
    {
      burningPoints,
      availablePoints,
      smsNotificationsEnabled,
      referral,
      showcaseParams,
      giftStoreLinkText,
    },
  ) {
    state.burningBonuses = burningPoints;
    state.availableBonuses = availablePoints;
    state.smsNotificationsEnabled = smsNotificationsEnabled;
    state.showcaseParams = showcaseParams;
    state.giftStoreLinkText = giftStoreLinkText;

    if (referral) {
      state.canUseRefCode = referral.canUseRefCode;
      state.referralCard = referral.card;
    } else {
      state.canUseRefCode = true;
      state.referralCard = null;
    }
  },
  setIsLoading(state, payload = true) {
    state.isLoading = !!payload;
  },
};

const getters: GetterTree<LoyaltyState, MainState> = {
  availableBonuses: ({ availableBonuses }) => availableBonuses,
  burningBonuses: ({ burningBonuses }) => burningBonuses,
  burningRules: ({ texts }) => texts.burningRules,
  loyaltyDetailRules: ({ texts }) => texts.loyaltyDetailRules,
  loyaltyShortRules: ({ texts }) => texts.loyaltyShortRules,
  smsNotificationsEnabled: ({ smsNotificationsEnabled }) => smsNotificationsEnabled,
};

export const loyaltyModule: Module<LoyaltyState, MainState> = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
