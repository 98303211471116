import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import type { ExtendedLocale } from '#root/src/utils/env-utils';
import {
  getDefaultLocaleFromMap,
  getI18nLocales,
  getLocaleMapFromEnv,
} from '#root/src/utils/env-utils';
import { getLocalCity, saveLocalCity } from '#root/src/utils/menu-utils';
import type MainState from '#root/src/types/store';
import { DEFAULT_CITIES } from '#root/src/constants/default-cities';
import type { WebsiteId } from '#root/src/env';
import type { InitialSiteData } from '@/types/dto/initialSiteData';
import { FooterType } from '@/store/vuex-store/modules/footerModule';
import { getLocaleCodeNameMap, getWebsiteId } from '@/utils/international.utils';
import { useMenuStore } from '@/store/pinia/menu/menu';

export const SET_LOCALE = 'SET_LOCALE';
export const SET_LOCALES = 'SET_LOCALES';
export const SET_LOCALES_MAP = 'SET_LOCALES_MAP';
export const LOCAL_STORAGE_ENABLE = 'LOCAL_STORAGE_ENABLE';
export const SET_SHOW_CONFIRM_CITY = 'SET_SHOW_CONFIRM_CITY';
function getLocaleCodes(locales: ExtendedLocale[]): string[] {
  return locales.map((locale) => locale.code);
}

function getLocalesMap(locales: ExtendedLocale[]) {
  const map: Record<string, string> = {};
  for (const row of locales) {
    map[row.code] = row.iso;
  }
  return map;
}

const localeMap = getLocaleMapFromEnv(getLocaleCodeNameMap());
const defLocale = getDefaultLocaleFromMap(localeMap);

const initState = (): MainState => {
  const websiteId: WebsiteId = getWebsiteId();

  return {
    localesMap: getLocalesMap(getI18nLocales(localeMap)),
    locales: getLocaleCodes(getI18nLocales(localeMap)),
    fullLocales: getI18nLocales(localeMap),
    locale: defLocale,
    defaultLocale: defLocale,
    websiteId,
    city: getLocalCity() ?? DEFAULT_CITIES[websiteId].ru,
    isNewMenu: false,
    showConfirmCityModal: false,
    // Используем вместо !!$nuxt. Накста больше нет....
    isSite: true,
  };
};

export const indexState = (): MainState => ({
  ...initState(),
});

export const indexMutations: MutationTree<MainState> = {
  setIsSite(state, payload) {
    state.isSite = payload.isSite;
  },
  [SET_LOCALE](state, locale) {
    if (state.locales.indexOf(locale) !== -1) {
      state.locale = locale;
    }
  },
  [SET_LOCALES](state, payload) {
    state.locales = payload.locales;
  },
  SET_DEFAULT_LOCALE(state, payload) {
    state.defaultLocale = payload.defaultLocale;
  },
  [SET_LOCALES_MAP](state, payload) {
    state.localesMap = payload.localesMap;
  },
  SET_WEBSITE_ID(state, payload) {
    state.websiteId = payload.websiteId;
  },
  SELECT_CITY(state, payload) {
    state.city = payload;
  },
  SET_NEW_MENU(state, payload) {
    state.isNewMenu = payload;
  },
  SET_SHOW_CONFIRM_CITY(state, payload) {
    state.showConfirmCityModal = payload;
  },
};

export const indexActions: ActionTree<MainState, MainState> = {
  UPDATE_SITE_DATA: (
    { dispatch },
    {
      siteData,
      urlPathname,
      locale,
    }: {
      siteData: InitialSiteData;
      urlPathname: string;
      locale: string;
    },
  ) => {
    const menuStore = useMenuStore();

    menuStore.setMenu(siteData.menu);

    menuStore.updateActiveMenuByUrl(urlPathname);

    dispatch(`footer/${FooterType.SET_FOOTER}`, {
      footer: siteData.footer,
    });
    dispatch('SET_LOCALE', locale);
  },
  SET_LOCALE(context, locale) {
    context.commit(SET_LOCALE, locale);
  },
  SET_LOCALES(context, locales) {
    context.commit(SET_LOCALES, { locales: getLocaleCodes(locales) });
    context.commit(SET_LOCALES_MAP, { localesMap: getLocalesMap(locales) });
  },
  SET_DEFAULT_LOCALE(context, defaultLocale) {
    context.commit('SET_DEFAULT_LOCALE', { defaultLocale });
  },
  SET_WEBSITE_ID(context, websiteId) {
    context.commit('SET_WEBSITE_ID', { websiteId });
  },
  SELECT_CITY({ commit }, city) {
    if (city?.cdek_id) {
      saveLocalCity(city);
      commit('SELECT_CITY', { ...city });
    }
  },
  SET_SHOW_CONFIRM_CITY({ commit }, payload) {
    commit(SET_SHOW_CONFIRM_CITY, payload);
  },
};

export const indexGetters: GetterTree<MainState, MainState> = {
  isLoggedInCabinet(state, getters, rootState, rootGetters) {
    // TODO: разобраться с типами
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isSsoAuth = rootState.auth.isSsoAuth;
    const accessToken = rootGetters['keycloak/accessToken'];
    const impersonationToken = rootGetters['auth/getImpersonationToken'];

    return Boolean(isSsoAuth || accessToken || impersonationToken);
  },
  isActiveCdekId(state, getters, rootState, rootGetters) {
    const user = rootGetters['cabinet/getUser'];
    return user && user.contragent?.uuid !== null && user.contragent?.cdekId === true;
  },
  currentLocale({ locale }) {
    return locale;
  },
  websiteId({ websiteId }) {
    return websiteId;
  },
  currentLocaleISO({ localesMap, locale }) {
    return localesMap[locale];
  },
  isRuSite({ websiteId }) {
    return websiteId === 'ru';
  },
  isKzSite({ websiteId }) {
    return websiteId === 'kz';
  },
  isKgSite({ websiteId }) {
    return websiteId === 'kg';
  },
  isAmSite({ websiteId }) {
    return websiteId === 'am';
  },
  isUzSite({ websiteId }) {
    return websiteId === 'uz';
  },
  isGeSite({ websiteId }) {
    return websiteId === 'ge';
  },
  isBySite({ websiteId }) {
    return websiteId === 'by';
  },
  isChineSite({ websiteId }) {
    return websiteId === 'cn';
  },
  isAzSite({ websiteId }) {
    return websiteId === 'az';
  },
  city({ city }) {
    return city;
  },
  isNewMenu({ isNewMenu }) {
    return isNewMenu;
  },
  showConfirmCityModal: ({ showConfirmCityModal }) => showConfirmCityModal,
  isSite: ({ isSite }) => isSite,
};

export const indexModule: Module<MainState, MainState> = {
  namespaced: false,
  getters: indexGetters,
  actions: indexActions,
  state: indexState,
  mutations: indexMutations,
};
