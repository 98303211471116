import { CLIENT_TYPE_SENDER, DELIVERY_TYPES } from '@/components/NewOrder/constants';
import type { CreateOrderState } from '@/types/store/createOrder';

const initialState = (): CreateOrderState => ({
  isRequestDirection: false,
  isRequestCalculate: false,
  isRequestTariff: false,
  sizeOptions: [],
  sizeOptionId: null,
  direction: {
    from: null,
    to: null,
    size: null,
  },
  currencies: [],
  payerCurrencies: [],
  activeCurrency: null,
  tabs: [],
  currentTab: null,
  currentTariff: null,
  errorAlerts: [],
  tabForm: {
    payer: CLIENT_TYPE_SENDER,
    recipientType: DELIVERY_TYPES.OFFICE,
    senderType: DELIVERY_TYPES.OFFICE,
    optionValue: null,
  },
  loyalty: {
    totalPrice: null,
    willBeEarnedPoints: null,
    availableForCurrentOrderPoints: null,
  },
  totalCost: null,
  deliveryPrice: null,
  isLoadTariffInfoError: false,
  isDirectionError: false,
  isTabClicked: false,
});

export default initialState;
