import throttle from 'lodash.throttle';
import type { DirectiveBinding } from 'vue';

const OFFSET = 0.2;
const MAIN_CLASS = 'appear-animation';
const PASSIVE_CLASS = MAIN_CLASS.concat('_hidden');
const INSIDE_PATTERNS = ['bebe', 'beeb', 'ebeb', 'ebbe'];

interface VAppearHtmlElement extends HTMLElement {
  $destroyScroll: () => void;
}

function scrollHandler(elem: VAppearHtmlElement) {
  const viewportHeight = window.innerHeight;
  const top = OFFSET * viewportHeight;
  const bottom = (1 - OFFSET) * viewportHeight;

  function recalculateLocation(element: VAppearHtmlElement) {
    const rect = element.getBoundingClientRect();
    const adaptiveTop = rect.height < top ? 0 : top;
    const adaptiveBottom = rect.height < viewportHeight - bottom ? viewportHeight : bottom;

    const patternList: [string, number][] = [
      ['b', adaptiveTop],
      ['b', adaptiveBottom],
      ['e', rect.top],
      ['e', rect.bottom],
    ];

    patternList.sort((a, b) => a[1] - b[1]);
    const pattern = patternList.map((e) => e[0]).join('');

    if (INSIDE_PATTERNS.includes(pattern)) {
      element.classList.remove(PASSIVE_CLASS);
    }
  }
  recalculateLocation(elem);
}

function subscribeScroll(func: ReturnType<typeof throttle>) {
  window.addEventListener('scroll', func);
  return () => {
    window.removeEventListener('scroll', func);
  };
}

function isValue(binding: DirectiveBinding) {
  return binding.value !== undefined && typeof binding.value === 'boolean';
}

export const appearDirective = {
  beforeMount(el: VAppearHtmlElement, binding: DirectiveBinding) {
    const active = isValue(binding) ? binding.value : true;
    if (!active) return;
    el.classList.add(PASSIVE_CLASS);
    el.classList.add(MAIN_CLASS);
  },
  mounted(el: VAppearHtmlElement, binding: DirectiveBinding) {
    const active = isValue(binding) ? binding.value : true;
    if (!active) return;
    const func = throttle(scrollHandler.bind(null, el), 300);
    const unsubscribe = subscribeScroll(func);
    setTimeout(() => {
      scrollHandler(el);
    }, 1000);
    el.$destroyScroll = () => unsubscribe();
  },
  unmounted(el: VAppearHtmlElement) {
    el.classList.remove(MAIN_CLASS, PASSIVE_CLASS);
    el.$destroyScroll && el.$destroyScroll();
  },
};
